import {
  Button as JoyButton,
  ButtonProps as JoyButtonProps,
  CircularProgress,
} from '@mui/joy'
import { ReactNode, useMemo } from 'react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/lib/theme'

export type ButtonProps = {
  children: string | ReactNode
  type?: string
  variant?: 'brand' | 'gray' | 'red' | 'green' | 'textBrand'
  onClick?: () => void
  to?: string
  show?: boolean
} & Omit<JoyButtonProps, 'variant'>

export function Button({
  children,
  sx,
  variant = 'brand',
  to,
  show = true,
  ...props
}: ButtonProps) {
  const customVariant = useMemo(() => {
    if (variant === 'brand') {
      return {
        background: myPalette.brand['600'],
        '&:hover': {
          background: myPalette.brand['800'],
        },
      }
    }

    if (variant === 'gray') {
      return {
        background: myPalette.gray['100'],
        color: myPalette.brand['700'],
        '&:hover': {
          background: myPalette.gray['100'],
          boxShadow: `inset 0px 0px 0px 3px ${myPalette.purple['100']}`,
        },
        '&.Mui-disabled': {
          color: myPalette.brand['400'],
        },
      }
    }

    if (variant === 'red') {
      return {
        background: myPalette.red['600'],
        '&:hover': {
          background: myPalette.red['700'],
        },
        '&.Mui-disabled': {
          background: myPalette.red['400'],
          color: myPalette.white.main,
        },
      }
    }

    if (variant === 'green') {
      return {
        background: myPalette.green['600'],
        '&:hover': {
          background: myPalette.green['700'],
        },
        '&.Mui-disabled': {
          background: myPalette.green['400'],
          color: myPalette.white.main,
        },
      }
    }

    if (variant === 'textBrand') {
      return {
        background: 'none',
        color: myPalette.brand['700'],
        '&:hover': {
          background: 'none',
          color: myPalette.brand['900'],
        },
        '&.Mui-disabled': {
          background: 'none',
          color: myPalette.brand['600'],
        },
      }
    }
  }, [variant])

  const link = useMemo(() => (to ? { component: Link, to } : {}), [to])

  if (!show) return null

  return (
    <JoyButton
      size='lg'
      loadingPosition='end'
      loadingIndicator={<CircularProgress size='sm' />}
      {...props}
      {...link}
      sx={{
        ...sx,
        ...customVariant,
        borderRadius: '30px',
        transition: '0.3s ease',
      }}
    >
      {children}
    </JoyButton>
  )
}
