import { Box } from '@mui/joy'
import { Chip } from './styled'

type ChipListProps = {
  items: string[]
}

export function ColumnChipList({ items = [] }: ChipListProps) {
  return (
    <Box>
      {items.map((role) => (
        <Chip key={role}>{role}</Chip>
      ))}
    </Box>
  )
}
