import * as Sentry from '@sentry/react'
import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { authStore } from '~/shared/api'

export const SentryGate = createGate()
export const domain = createDomain('shared.lib.sentryModel')

export const setUser = domain.createEvent<{
  id: UniqueId
  email: string
}>()
export const setUserFx = domain.createEffect<
  { id: UniqueId; email: string },
  void
>({
  async handler({ email, id }) {
    Sentry.setUser({ email, id })
  },
})

sample({
  clock: setUser,
  target: setUserFx,
})

sample({
  clock: SentryGate.open,
  filter: () => Boolean(authStore.currentUserId && authStore.email),
  fn() {
    return {
      id: authStore.currentUserId,
      email: authStore.email,
    }
  },
  target: setUserFx,
})

export const clearUser = domain.createEvent()
export const clearUserFx = domain.createEffect({
  async handler() {
    Sentry.setUser({ email: '-', id: '-' })
  },
})

sample({
  clock: clearUser,
  target: clearUserFx,
})
