import { Box, styled } from '@mui/joy'

export const CellWrapper = styled(Box)`
  display: flex;
  &,
  & > *,
  > * {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    text-wrap: nowrap;
    width: 100%;
  }
`

export const HiddenCellWrapper = styled(Box)`
  height: 0 !important;
  max-height: 0 !important;
  text-wrap: nowrap;
  position: relative;
  opacity: 0;
  z-index: -1;
`
