import { Box, Tooltip } from '@mui/joy'
import { GridCellProps, GridCell as MuiGridCell } from '@mui/x-data-grid'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  CellWrapper,
  HiddenCellWrapper,
} from '~/shared/ui/AsyncGrid/GridCell/styled'

export function GridCell(props: GridCellProps) {
  const ref = useRef<HTMLElement | null>()
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>()

  useEffect(() => {
    if (!ref.current) return

    setIsTooltipVisible(ref.current.offsetWidth < ref.current.scrollWidth)
  }, [props, ref])

  const renderValue = useMemo(
    () => props.children || <span>{props.value}</span>,
    [props.children, props.value],
  )

  if (props.field === '__check__') {
    return <MuiGridCell {...props}>{props.children}</MuiGridCell>
  }

  return (
    <MuiGridCell {...props}>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <HiddenCellWrapper ref={ref}>{renderValue}</HiddenCellWrapper>

        <Tooltip
          variant='outlined'
          arrow
          title={
            isTooltipVisible && (
              <Box sx={{ maxWidth: '800px' }}>{renderValue}</Box>
            )
          }
        >
          <CellWrapper
            data-testid={`data-gird-cell-${props.field}`}
            data-cell-element
          >
            {renderValue}
          </CellWrapper>
        </Tooltip>
      </Box>
    </MuiGridCell>
  )
}
