import { GlobalStyles as JoyGlobalStyles } from '@mui/joy'

export const GlobalStyles = () => (
  <JoyGlobalStyles
    styles={{
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      body: {
        background: '#DAE4FF',
        overscrollBehavior: 'none',
        '--webkit-overscroll-behavior': 'none',
      },
    }}
  />
)
