import {
  ModalDialog,
  Modal as JoyModal,
  ModalProps as JoyModalProps,
  ModalDialogProps,
  ModalClose,
  DialogTitle,
  ModalCloseProps,
  Box,
} from '@mui/joy'
import { ReactNode } from 'react'

type ModalProps = {
  isShow: boolean
  children: ReactNode
  onCancel?: () => void
  title?: string
  dialogProps?: ModalDialogProps
  closeProps?: ModalCloseProps
  modalProps?: JoyModalProps
}

export function Modal({
  isShow,
  children,
  onCancel,
  title,
  dialogProps,
  closeProps,
  modalProps,
}: ModalProps) {
  return (
    <JoyModal
      open={isShow}
      onClose={onCancel}
      disableAutoFocus
      keepMounted={false}
      {...modalProps}
    >
      <ModalDialog
        {...dialogProps}
        sx={{
          padding: '24px',
          borderRadius: '24px',
          overflowY: 'auto',
          ...dialogProps?.sx,
        }}
      >
        {Boolean(onCancel) && (
          <ModalClose
            {...closeProps}
            sx={{ top: '22px', right: '16px', ...closeProps?.sx }}
          />
        )}

        {title && (
          <DialogTitle sx={{ margin: '0 auto', marginBottom: '36px' }}>
            {title}
          </DialogTitle>
        )}

        <Box>{children}</Box>
      </ModalDialog>
    </JoyModal>
  )
}
