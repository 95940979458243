import Pino from 'pino'

let clientLogger: Pino.Logger

function createOrInitLogger() {
  if (typeof clientLogger === 'undefined') {
    clientLogger = Pino({
      level: 'info',
    })
  }
  return clientLogger
}

export const logger = createOrInitLogger()
