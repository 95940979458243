import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { omit } from 'lodash'
import { AxiosErrorType } from '~/shared/api'

export const sentryCaptureHttpsException = (error: AxiosErrorType) => {
  const responseData = error?.response?.data
  const config = error.config
  const status = error?.response?.status
  const message = getMessage(error)

  Sentry.captureException(message?.length ? new AxiosError(message) : error, {
    level: status === 401 ? 'info' : 'error',
    extra: {
      url: config?.url || null,
      requestData: getRequestData(config),
      responseData: responseData ? JSON.stringify(responseData) : null,
      status: status || null,
    },
  })
}

const getMessage = (error: AxiosErrorType) => {
  const data = error?.response?.data
  if (data?.message) return data.message

  if (data?.errors?.length)
    return data.errors.map((error) => error?.detail).join(', ')

  return null
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const getRequestData = (config: any) => {
  try {
    return config?.data
      ? JSON.stringify(omit(JSON.parse(config.data), 'password'))
      : null
  } catch (e) {
    return null
  }
}
