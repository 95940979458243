import { Box, Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ChipStatus } from 'src/shared/ui/Chips'
import { Car } from '~/shared/api'
import {
  AsyncAutocompleteInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { FormValues } from './model'

export type FormProps = {
  canEdit?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  submitText?: string
  defaultValues: FormValues
  onSuccess: (values: FormValues) => void
  getCarError?: boolean
}

export function Form({
  canEdit,
  defaultValues,
  isLoading,
  onSuccess,
  isSubmitting,
  getCarError,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = !isEditing || isSubmitting

  const form = useForm<FormValues>({
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <Grid container spacing={2.5}>
          <Grid xs={12}>
            <FormTitle>Не блокировать авто</FormTitle>
            {getCarError && (
              <ChipStatus
                variant='red'
                label='Ошибка получения автомобилей'
                sx={{
                  margin: '12px 0',
                }}
              />
            )}
            <Box data-testid='container-carsWhitelists'>
              <AsyncAutocompleteInput
                name='carsWhitelists'
                placeholder='Автомобили'
                fetchOptions={Car.fetchOptions}
                multiple
                skeletonShow={isLoading}
                readOnly={disabled}
                autocompleteXs={6}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <FormButtons loading={isSubmitting} />
    </FormProvider>
  )
}
