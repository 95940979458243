import { createDomain, sample } from 'effector'
import { viewerModel } from '~/entities/viewer'
import { User } from '~/shared/api'
import { replaceFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { sentryModel } from '~/shared/lib/sentry'

import { FormValues } from './lib'

export const domain = createDomain('features.auth.by-credentials.login')

export const loginFx = domain.createEffect<
  FormValues,
  { token: string; id: UniqueId; email: string }
>({
  async handler(credentials) {
    return await User.login(credentials)
  },
})

export const getUserMeFx = domain.createEffect({
  async handler() {
    return User.me()
  },
})

sample({
  clock: loginFx.doneData,
  fn({ token }) {
    return token
  },
  target: viewerModel.tokenSaved,
})

sample({
  clock: loginFx.doneData,
  fn({ email }) {
    return email
  },
  target: viewerModel.emailSaved,
})

sample({
  clock: loginFx.doneData,
  fn({ id }) {
    return id
  },
  target: viewerModel.currentUserIdSaved,
})

sample({
  clock: loginFx.doneData,
  fn({ id, email }) {
    return { id, email }
  },
  target: sentryModel.setUser,
})

sample({
  clock: viewerModel.tokenSaved,
  target: getUserMeFx,
})

sample({
  clock: getUserMeFx.doneData,
  target: viewerModel.saveUserOptionsFx,
})

sample({
  clock: loginFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка входа, проверьте правильность введенных данных',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

/* Submitted */
export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: loginFx,
})

sample({
  clock: getUserMeFx.doneData,
  fn() {
    return '/'
  },
  target: replaceFx,
})

sample({
  clock: getUserMeFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получение настроек пользователя',
      variant: 'warning' as const,
    }
  },
  target: snackbarEnqueued,
})
