import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
  children: ReactNode
  title: string
}

export function PageContainer({ children, title }: Props) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  )
}
