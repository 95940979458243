import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { Wrapper, ContentWrapper } from './styled'
import { Header } from './ui/Header'

type Props = {
  children?: ReactNode
}

export function MainLayout({ children }: Props) {
  return (
    <Wrapper>
      <Header />
      <ContentWrapper>{children || <Outlet />}</ContentWrapper>
    </Wrapper>
  )
}
