import { Warning } from '@mui/icons-material'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/joy'
import * as Sentry from '@sentry/react'
import * as React from 'react'

type Props = {
  children: React.ReactNode
}

function ErrorFallback() {
  return (
    <Alert
      variant='soft'
      color='danger'
      invertedColors
      startDecorator={
        <CircularProgress size='lg' color='danger'>
          <Warning />
        </CircularProgress>
      }
      sx={{ alignItems: 'flex-start', gap: '1rem', borderRadius: '30px' }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography level='title-md'>На странице произошла ошибка</Typography>
        <Typography level='body-md'>
          Если после обновления страницы ошибка не проходит, обратитесь к
          администратору
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button
            variant='solid'
            size='lg'
            onClick={() => window.location.reload()}
          >
            Обновить
          </Button>
        </Box>
      </Box>
    </Alert>
  )
}

export function ErrorBoundary(props: Props) {
  const { children } = props
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}
